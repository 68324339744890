import React from 'react'
import { Layout, SEO } from '../components'
import { Link, graphql } from 'gatsby'

export const query = graphql`
  query {
    notFound: file(absolutePath: { regex: "/not_found.svg/" }) { publicURL },
  }
`

const NotFound = ({data}) => (
  <Layout>
    <SEO title="Not Found" />

    <section style={{padding: '80px 40px 60px 40px'}}>
      <img src={data.notFound.publicURL} />

      <h1 style={{textAlign: 'center', marginTop: '50px'}}>Woops!</h1>
      <h2 style={{textAlign: 'center', marginTop: '20px'}}>It doesn't look as if that page can be found!</h2>

      <Link className="button primary" to="/">Go To Home</Link>
    </section>
  </Layout>
)

export default NotFound
